<script setup lang="ts">
import {cabinTypes} from "~/utils/flight/search-utils";
import type {CabinType} from "~/types/flight/search/CabinType";

const props = defineProps<{
    modelValue: CabinType,
    hasRoute?: boolean
}>()
const emit = defineEmits(['update:modelValue'])

const cabin_types = cabinTypes()
const activeItem = ref(props.modelValue)


const items = computed(() =>
    cabin_types.map((item: CabinType) => {
        return [
            {
                label: item.fa_name,
                onSelect: () => activeItem.value = item
            }
        ]
    }))

watch(() => activeItem.value, () => emit('update:modelValue', activeItem.value))

</script>

<template>
    <u-dropdown-menu
        :items="items"
        :popper="{ placement: 'bottom-start' }"
        :ui="{base: 'p-2', width: 'w-60', padding: 'p-1', item: {active: 'text-primary bg-primary-50'}}"
    >
        <template #item="{ item }">
            <div class="w-full flex justify-between items-center">
                <span class="truncate tracking-tight">{{ item.label }}</span>
                <u-icon
                    v-if="item.label === activeItem.fa_name"
                    name="i-material-symbols-check"
                    class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto text-primary"
                />
            </div>
        </template>
        <u-button
            color="neutral"
            variant="outline"
            :label="activeItem.fa_name"
            :trailing-icon="chevronDownIcon()"
            :ui="{base: hasRoute ? 'text-gray-800 hover:text-gray-500 rounded-full' : 'text-gray-800 hover:text-primary transition-colors duration-400 rounded-full'}"
        />
    </u-dropdown-menu>
</template>
